import React from 'react';
import PropTypes from 'prop-types';
import '../css/typography.css';

const ContentDossier = ({ id, dossier }) => {

  
  return (
    <section id={id} className="text-gray-600">
      <div className="px-4 lg:px-40 py-12 pb-56 mx-auto flex flex-col">
        <h1 className="text-4xl sm:text-6xl font-roboto font-bold tracking-tight text-gray-900 text-center">{dossier.title}</h1>
        <div className="flex flex-col sm:flex-row mt-4">
          <div className="sm:order-last sm:w-full sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
            <p className="sm:text-xl text-lg font-roboto font-bold tracking-tight text-gray-900">
              {dossier.description}
            </p>
            {(dossier.listTitle !== "") ? 
              <h2 className="text-2xl title-font mt-8 font-roboto font-bold tracking-tight text-gray-900">
                {dossier.listTitle}
              </h2> : 
              null}
            {dossier.list.map((el) => (
              <ul className="list-disc sm:ml-8 mx-8 mt-2">
                <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">{el.text}</li>
              </ul>
            ))}
            <h2 className="text-2xl  title-font mt-8 font-roboto font-bold tracking-tight text-gray-900">
              {dossier.docsTitle}
            </h2>
            <ul className="list-disc sm:ml-8 mx-8 mt-2">
              {dossier.docs.map((el) => (
                <li id={el.id} className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  {el.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ContentDossier.defaultProps = {
  theme: 'indigo',
};

ContentDossier.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ContentDossier;
