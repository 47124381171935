import React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';


const ContentDossierPrice = ({ id, dossier }) => {

  return (
    <section id={id} className="text-gray-600 body-font">
      <div className="container px-8 sm:px-5 py-12 mx-auto flex flex-col">
        <div className="lg:w-full mx-auto">
          <h1 className="text-center sm:text-6xl text-4xl font-roboto font-bold tracking-tight text-gray-900">{dossier.title}</h1>
          <div className="flex flex-col sm:flex-row mt-8 sm:mt-16">

            {/* Price section */}
            <div className="sm:w-full md:w-1/3 lg:w-2/5 sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <div className="sm:mb-4 mb-4 px-8 py-2">
                <h2 className="text-center text-3xl font-roboto font-bold tracking-tight text-gray-900">Prezzo</h2>
                <p className="text-center text-blue-accent-700"></p>
              </div>
              <div className="sm:w-full text-center">
                {/* Image */}
                <div className="sm:order-none order-first h-40 w-40 inline-flex items-center justify-center rounded-full bg-blue-accent-700 text-white flex-shrink-0">
                  <p className="leading-relaxed font-bold text-4xl text-white">
                    {dossier.price}
                    <FontAwesomeIcon
                      icon={faEuroSign}
                      className="fa-solid flex-shrink-0 h-8 w-8 text-white"
                    />
                  </p>
                </div>
                {/* Description */}

              </div>
            </div>

            <div className="order-first sm:order-last sm:w-full md:w-1/2 lg:w-full sm:pl-8 sm:py-8 sm:border-l sm:border-gray-400 sm:border-b-0 border-b border-gray-400 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <p className="sm:text-xl text-lg font-roboto font-bold tracking-tight text-gray-900">
                {dossier.description}
              </p>
              {(dossier.listTitle !== "") ?
                <h2 className="text-3xl mt-8 font-roboto font-bold tracking-tight text-gray-900">
                  {dossier.listTitle}
                </h2> :
                null}
              {dossier.list.map((el) => (
                <ul className="list-disc sm:ml-8 mx-8 mt-2">
                  <li className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">{el.text}</li>
                </ul>
              ))}
              <h2 className="text-3xl title-font mt-8 font-roboto font-bold tracking-tight text-gray-900">
                {dossier.docsTitle}
              </h2>
              <ul className="list-disc sm:ml-8 mx-8 mt-2">
                {dossier.docs.map((el) => (
                  <li id={el.id} className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                    {el.text}
                  </li>
                ))}
              </ul>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

ContentDossierPrice.defaultProps = {
  theme: 'indigo',
};

ContentDossierPrice.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ContentDossierPrice;
