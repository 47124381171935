
import React from 'react';
import Layout from '../../components/layout/Layout';
import dossier from '../../data/pratiche-patenti/patente-internazionale/dossier.json';
import ContentDossier from '../../components/ContentDossiers';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentDossierPrice from '../../components/ContentDossierPrice';
import Seo from '../../components/layout/SEO';

const PatenteInternazionale = () => {
  return (
    <Layout>
      <Seo
        title={"Patente Internazionale | Autoscuola Faro, Pisa"}
        description={"Richiedi la patente internazionale per guidare veicoli in determinati stati esteri. Bastano le tue fototessere e la patente originale."}
        keywords={[
          "Richiedere Patente Internazionale Pisa",
          "Ottenere Patente Internazionale Pisa",
          "Permesso Internazionale di Guida Pisa"
        ]}
      />

      <PageNavigator link="/pratiche-patenti" text="Pratiche patenti" />
      <ContentDossierPrice dossier={dossier} />
    </Layout>
  );
};

export default PatenteInternazionale;
